import React from 'react'
import { Link } from 'gatsby'

import Img from "gatsby-image"
import newsStyles from "./news-list.module.scss"

function NewsList(props) {
  return (
    <div className={newsStyles.listWrapper}>
      {props.items.map((item) => {
        return (
          <Link to={`/news/${item.node.fields.slug}`} className={newsStyles.item} key={item.node.id}>
            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid} />
            <div className={newsStyles.body}>
              <time className={newsStyles.meta} datetime={item.node.frontmatter.date} pubdate="pubdate">{item.node.frontmatter.date}</time>
              <h2 className={newsStyles.title}>{item.node.frontmatter.title}</h2>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default NewsList
