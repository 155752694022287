import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/ui/layout/layout'
import Hero from '../components/ui/hero/hero'
import Header from '../components/ui/header/header'
import NewsList from '../components/ui/news-list/news-list'
import SEO from "../components/seo"

function NewsPage() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {posttype: {eq: "post"}}}, sort: {order: DESC, fields: frontmatter___date}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD MMMM YYYY")
              description
              title
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 540) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout hasNoBorder={true}>
      <SEO title="News" description="The place where you can find up to date information about Bazar." />
      <Header />
      <Hero isSmall={true}>
        <h1 className="section-hero__title">News</h1>
        <p className="section-hero__description">What is happening with Bazar?</p>
      </Hero>
      <main id="content" style={{'backgroundColor': '#f8fbff'}}>
        <NewsList items={data.allMarkdownRemark.edges} />
      </main>
    </Layout>
  )
}

export default NewsPage